<template>
  <div>
    <CheckBrowser url="main.html"></CheckBrowser>
  </div>
</template>
<script lang="ts">
import CheckBrowser from "@/components/CheckBrowser.vue";
import { Component, Vue } from "vue-facing-decorator";

@Component({
  components: { CheckBrowser },
})
export default class index extends Vue {}
</script>

<style scoped lang="scss">
#content {
  width: 100%;
  height: 100vh;
  background-color: #cccccc;
}

#content img {
  width: 100%;
}
</style>
