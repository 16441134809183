<template>
  <div>
    <div id="content" v-if="show">
      <img src="@/assets/open.png" alt="" />
    </div>
    <slot v-if="!show"></slot>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from "vue-facing-decorator";

@Component
export default class index extends Vue {
  show = false;
  @Prop({})
  url: string | null = null;

  created() {
    this.show = this.isAliPay() || this.isWechat() || this.isMQQ();
    if (!this.show && this.url != null) {
      location.href = this.url;
    }
  }

  isAliPay() {
    return /AlipayClient/i.test(window.navigator.userAgent);
  }

  isWechat() {
    return /MicroMessenger/i.test(navigator.userAgent);
  }

  isMQQ() {
    return /QQ\/\d/i.test(window.navigator.userAgent);
  }
}
</script>

<style scoped lang="scss">
#content {
  width: 100%;
  height: 100vh;
  background-color: #cccccc;
}

#content img {
  width: 100%;
}
</style>
