<script lang="ts">
import { Options, Vue } from "vue-class-component";
@Options({})
export default class Draw extends Vue {
  show: Map<string, boolean> = new Map([
    ["markSix", true],
    ["macaoSix", true],
    ["newMacaoSix", true],
  ]);
  active: any;
  showTab(name: string) {
    for (const key in this.show) {
      this.show.set(key, false);
    }
    this.show.set(name, true);
  }
}
</script>

<template>
  <van-tabs v-model:active="active">
    <van-tab title="港彩">
      <iframe
        class="box"
        src="draw/mark_six.html"
        frameborder="0"
        scrolling="no"
      ></iframe>
    </van-tab>
    <van-tab title="新澳门">
      <iframe
        class="box"
        src="draw/new_macau_six.html"
        frameborder="0"
        scrolling="no"
      ></iframe>
    </van-tab>
    <van-tab title="澳门">
      <iframe
        class="box"
        src="draw/macau_six.html"
        frameborder="0"
        scrolling="no"
      ></iframe>
    </van-tab>
  </van-tabs>
</template>

<style scoped lang="scss">
.box {
  width: 100%;
  height: 120px;
}
</style>
