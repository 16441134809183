<template>
  <div class="home">
    <CheckBrowser>
      <div class="mobile-wrap center">
        <div class="logo">
          <img src="@/assets/web_logo.png" alt="" />
        </div>
        <swiper
          :spaceBetween="30"
          :autoplay="{
            delay: 2500,
            disableOnInteraction: false,
          }"
          :modules="modules"
          class="mySwiper"
        >
          <swiper-slide v-for="(item, key) in banner" :key="key">
            <a :href="item.url" target="_blank">
              <img class="swiper-slide-image" :src="item.image" alt="" />
            </a>
          </swiper-slide>
        </swiper>
        <div class="download">
          <div class="title">
            <img src="@/assets/img/62371a_514x56.png" alt="" />
          </div>
          <div class="conts">
            <div class="list">
              <ul>
                <li class="good" v-for="(item, key) in appList" :key="key">
                  <a :href="item.down" target="_blank" style="display: block">
                    <div class="intr">
                      <div class="imgs">
                        <img :src="item.image" alt="" />
                      </div>
                      <div class="text">
                        <div class="button">
                          <button>下载</button>
                        </div>
                        <div class="name">{{ item.name }}</div>
                        <div class="score">
                          <img src="@/assets/img/7f8cdb_151x23.png" alt="" />
                          <span>9.9分</span>
                        </div>
                        <div class="labs">
                          <span class="down"> {{ item.downCount }}</span> |
                          <span class="size">{{ item.size }} </span> |
                          <span>中文 </span>
                        </div>
                      </div>
                    </div>
                    <div class="activity">
                      <div class="labs">
                        <i>优惠活动</i>
                      </div>
                      <div class="txts">
                        {{ item.description }}
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <Draw></Draw>
        <a
          class="banner-home"
          v-for="(item, key) in config"
          :key="key"
          :href="item.url"
          target="_blank"
        >
          <img class="banner" :src="item.image" alt=""
        /></a>
        <div class="plan-title" v-html="sixPlanConfig.mark_six_plan_list"></div>
        <div class="plan" v-for="(item, key) in markSixPlanList" :key="key">
          <span v-html="item"></span>
        </div>
        <div
          class="plan-title"
          v-html="sixPlanConfig.new_macao_six_plan_list"
        ></div>
        <div class="plan" v-for="(item, key) in newMacaoSixPlanList" :key="key">
          <span v-html="item"></span>
        </div>
        <div class="plan-title" v-html="sixPlanConfig.macao_six_plan"></div>
        <div class="plan" v-for="(item, key) in macaoSixPlanList" :key="key">
          <span v-html="item"></span>
        </div>
        <div class="honor">
          <img src="@/assets/img/155b36_750x322.png" alt="" />
        </div>
        <div class="footer">
          <div class="footer_logo">
            <img src="@/assets/img/f97fbb_186x44.png" alt="" />
          </div>
          <div class="text">
            <p>2022卡塔尔世界杯官方合作伙伴</p>
            <p>澳门博彩协会</p>
          </div>
        </div>
      </div>
    </CheckBrowser>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import axios from "axios";
import Draw from "@/components/Draw.vue";
import CheckBrowser from "@/components/CheckBrowser.vue";
@Options({
  components: { CheckBrowser, Swiper, SwiperSlide, Draw },
})
export default class HomeView extends Vue {
  modules = [Autoplay];
  config: any = [];
  appList: any = [];
  banner: any = [];
  markSixPlanList: any = [];
  macaoSixPlanList: any = [];
  newMacaoSixPlanList: any = [];
  sixPlanConfig: any = [];
  size = "1000";
  created() {
    this.fetchData();
  }

  shuffleArray(array: any) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }
  fetchData() {
    axios.get("data/config.json").then((response) => {
      this.config = this.shuffleArray(response.data);
    });
    axios.get("data/app_list.json").then((response) => {
      this.appList = this.shuffleArray(response.data);
    });
    axios.get("data/banner_list.json").then((response) => {
      this.banner = this.shuffleArray(response.data);
    });
    axios.get("data/mark_six_plan_list.data").then((response) => {
      this.markSixPlanList = response.data.split("\r\n");
    });
    axios.get("data/macao_six_plan_list.data").then((response) => {
      this.macaoSixPlanList = response.data.split("\r\n");
    });
    axios.get("data/six_plan_config.json").then((response) => {
      this.sixPlanConfig = response.data;
    });
    axios.get("data/new_macao_six_plan_list.data").then((response) => {
      this.newMacaoSixPlanList = response.data.split("\r\n");
    });
  }
}
</script>
<style scoped lang="scss">
.notice {
  background-color: #ffffff;
  border-radius: 5px;
  margin: 2px;
  font-size: 13px;
  padding: 10px;
  box-shadow: 0 0.05rem 0.1rem #d7dae4;
}

.notice img {
  height: 20px;
}
.plan {
  font-size: 0.3rem;
  padding: 5px;
}

.banner {
  width: 100%;
}
.banner-home {
  display: block;
  box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.4);
  margin: 5px;
  border-radius: 0.1rem;
}
.banner-home img {
  border-radius: 0.1rem;
  width: 100%;
}
.plan-title {
  padding: 10px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  background-color: #23242e;
  color: #ffffff;
  //text-shadow: 1px 1px #d24a4a, 1px 1px 1px #f48528;
}
.plan td {
  padding: 0.1rem;
}
.draw-box {
  width: 100%;
  height: 3rem;
  overflow: hidden;
}

.move-touch {
  -webkit-overflow-scrolling: touch;
}

.auto-center ul li .pict {
  display: table;
  width: 100%;
  text-align: center;
}

.swiper-slide-image {
  width: 100%;
}

.auto-center ul li .pict i {
  vertical-align: middle;
  display: table-cell;
}

.vague {
  -webkit-filter: blur(2px);
  transition: 0.3s;
  -webkit-transition: 0.3s;
}

.rogate {
  transition: 0.3s;
  -webkit-transition: 0.3s;
}

.rogate:hover {
  transform: rotate(90deg);
}

.arrow {
  position: relative;
  line-height: 1;
  margin-left: 0.1rem;
}

.arrow:before {
  content: "";
  position: absolute;
  top: -0.02rem;
  left: 0;
  width: 0;
  height: 0;
  border-bottom: 0.08rem solid;
  border-right: 0.08rem solid transparent;
  border-top: 0.08rem solid transparent;
  border-left: 0.08rem solid transparent;
}

.arrow:after {
  content: "";
  position: absolute;
  top: 0.18rem;
  left: 0;
  width: 0;
  height: 0;
  border-top: 0.08rem solid;
  border-right: 0.08rem solid transparent;
  border-bottom: 0.08rem solid transparent;
  border-left: 0.08rem solid transparent;
}

.arrow.one:before {
  display: none;
}

.arrow.one:after {
  top: 0.14rem;
}

.border-top:before {
  background: #dadada;
  content: "";
  position: absolute;
  height: 1px;
  transform-origin: 0 0;
  transform: scale(0.5, 0.5);
  -webkit-transform: scale(0.5, 0.5);
  -moz-transform: scale(0.5, 0.5);
  width: 200%;
  top: 0;
  left: 0;
}

.border-bottom:after {
  background: #dadada;
  content: "";
  position: absolute;
  height: 1px;
  transform-origin: 0 0;
  transform: scale(0.5, 0.5);
  -webkit-transform: scale(0.5, 0.5);
  -moz-transform: scale(0.5, 0.5);
  width: 200%;
  bottom: -1px;
  left: 0;
}

.flex-slide {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.flex-slide > * {
  flex-shrink: 0;
}

.box-space-between {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
}

.box-space-between > * {
  flex-shrink: 0;
}

.touch-state {
  position: relative;
}

.touch-state label {
  position: relative;
  background: #fff;
  display: block;
  width: 0.9rem;
  height: 0.45rem;
  border: 1px solid #ccc;
  border-radius: 0.3rem;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.touch-state label:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 0;
  width: 0.41rem;
  height: 0.41rem;
  background: #fff;
  box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.4);
  border-radius: 50%;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.touch-state input {
  position: absolute;
  z-index: 0;
  opacity: 0;
  left: 45%;
  top: 20%;
}

.touch-state input:checked + label {
  background: #47b92c;
  border-color: #47b92c;
}

.touch-state input:checked + label:after {
  left: calc(100% - 0.41rem);
}

.swiper-wrapper {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  display: -webkit-box;
  position: relative;
}

.swiper-slide {
  flex-shrink: 0;
  position: relative;
}

.mohu {
  filter: blur(1px);
  -webkit-filter: blur(1px);
  -moz-filter: blur(1px);
  -ms-filter: blur(1px);
  filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius=1, MakeShadow=false);
}

.fixed-top {
  position: fixed;
  left: 0;
  width: 100%;
  top: 0;
  z-index: 2;
  background: #fff;
}

.fixed-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  background: #fff;
}

.column {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}

.column > * {
  column-count: 1;
  flex-grow: 1;
  flex-shrink: 0;
  text-align: center;
}

a,
body,
button,
dd,
dl,
dt,
h1,
h2,
h3,
h4,
h5,
i,
img,
input,
li,
p,
select,
span,
strong,
textarea,
ul {
  margin: 0;
  padding: 0;
}

b,
h1,
h2,
h3,
h4,
h5,
h6,
strong {
  font-weight: 400;
}

table {
  border-spacing: 0;
}

body {
  font-family: "microsoft yahei", Arial;
  font-size: 0.26rem;
  line-height: 1;
  color: #333;
  -webkit-user-select: none;
  -webkit-text-size-adjust: 100% !important;
  text-size-adjust: 100% !important;
  -moz-text-size-adjust: 100% !important;
}

::-webkit-scrollbar {
  width: 0;
  height: 0;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

li {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: #333;
  outline: 0;
}

img {
  border: none;
  vertical-align: middle;
}

em,
i,
var {
  font-style: normal;
}

button {
  font-family: "microsoft yahei", Arial;
  outline: 0;
  cursor: pointer;
  font-size: 0.26rem;
  border: none;
}

iframe {
  border: none;
}

input,
select,
textarea {
  outline: 0;
  font-family: "microsoft yahei", Arial;
  font-size: 0.26rem;
  border-radius: 0;
  border: none;
  background: 0 0;
}

input:disabled {
  opacity: 1;
  color: #333;
}

button,
input[type="button"],
input[type="date"],
input[type="number"],
input[type="password"],
input[type="reset"],
input[type="submit"],
input[type="text"],
textarea {
  -webkit-appearance: none;
  border: none;
  background: 0 0;
}

textarea {
  resize: none;
}

.ico {
  display: inline-block;
  vertical-align: middle;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.clear {
  clear: both;
}

.clearfix:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.clearfix {
  zoom: 1;
}

.hide {
  display: none !important;
}

.mobile-wrap {
  position: relative;
  min-height: 100vh;
  width: 100%;
  float: left;

  //background: url(@/assets/img/63ab1c_750x414.jpg) top center no-repeat;
  background-size: 100% auto;
}

body,
html {
  width: 100%;
  height: 100%;
}
.footer_logo {
  width: 100%;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo {
  width: 100%;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #23242e;
  border-bottom: 2px solid #000000;
}

.logo img {
  height: 1.5rem;
}

.banner {
  width: 100%;
}

.banner ul {
  width: 100%;
}

.banner ul li {
  width: 100%;
}

.banner ul li img {
  width: 100%;
}

.banner .swiper-pagination {
  bottom: 0.12rem !important;
}

.banner .swiper-pagination .swiper-pagination-bullet {
  width: 0.12rem;
  margin: 0 0.06rem;
  height: 0.12rem;
  opacity: 1;
  background-color: #fff;
}

.banner
  .swiper-pagination
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #fffd44;
}

.download {
  width: 100%;
}

.download .title {
  width: 100%;
  height: 1.36rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.download .title img {
  width: 5.14rem;
}

.download .conts {
  width: 100%;
  padding: 0 0.16rem;
}

.download .conts .list {
  width: 100%;
}

.download .conts .list ul {
  width: 100%;
}

.download .conts .list ul li {
  width: 100%;
  border-radius: 0.3rem;
  box-shadow: 0 0.05rem 0.1rem #d7dae4;
  border: 1px solid #e7ebf0;
  min-height: 1rem;
  padding: 0.2rem;
  padding-bottom: 0;
  margin-bottom: 0.26rem;
  display: block;
}

.download .conts .list ul li .intr {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.download .conts .list ul li .intr .button {
  width: auto;
  position: absolute;
  right: 0;
  bottom: 0.2rem;
}

.download .conts .list ul li .intr .button button {
  width: 1.28rem;
  height: 0.58rem;
  background-color: #2d7ee2;
  text-align: center;
  line-height: 0.58rem;
  font-size: 0.28rem;
  border-radius: 0.29rem;
  color: #fff;
}

.download .conts .list ul li .intr .imgs {
  width: 1.4rem;
}

.download .conts .list ul li .intr .imgs img {
  width: 1.4rem;
}

.download .conts .list ul li .intr .text {
  width: calc(100% - 1.4rem);
  padding-left: 0.3rem;
}

.download .conts .list ul li .intr .text .name {
  width: 100%;
  height: 0.56rem;
  line-height: 0.56rem;
  font-size: 0.32rem;
  color: #000;
}

.download .conts .list ul li .intr .text .score {
  width: 100%;
  height: 0.28rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.download .conts .list ul li .intr .text .score img {
  width: 1.52rem;
  position: relative;
}

.download .conts .list ul li .intr .text .score span {
  font-size: 0.26rem;
  color: #fc4a1a;
  margin-left: 0.06rem;
  position: relative;
  top: 1px;
}

.download .conts .list ul li .intr .text .labs {
  width: 100%;
  line-height: 0.58rem;
  font-size: 0.22rem;
  color: #6f6f6f;
}

.download .conts .list ul li .intr .text .labs span {
  margin-right: 0.06rem;
}

.download .conts .list ul li .activity {
  width: 100%;
  height: 0.72rem;
  display: flex;
  justify-content: space-between;
}

.download .conts .list ul li .activity .labs {
  width: 1.32rem;
  height: 0.72rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.download .conts .list ul li .activity .labs i {
  display: inline-block;
  width: auto;
  padding: 0 0.12rem;
  height: 0.36rem;
  border: 1px solid #fc4a1a;
  border-radius: 0.06rem;
  font-size: 0.22rem;
  color: #fc4a1a;
  background-color: #fcf3f6;
  line-height: 0.34rem;
}

.download .conts .list ul li .activity .txts {
  width: calc(100% - 1.32rem);
  line-height: 0.72rem;
  font-size: 0.22rem;
  color: #6f6f6f;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.download .conts .list ul li.good:after {
  content: "";
  position: absolute;
  right: 0;
  margin: -106px 8px;
  width: 0.8rem;
  height: 0.8rem;
  background: url(@/assets/img/9b4c59_80x80.png) center no-repeat;
  background-size: 0.8rem 0.8rem;
}

.honor {
  width: 100%;
  float: left;
  background-color: #edeef1;
  padding: 0.3rem 0;
}

.honor img {
  width: 100%;
}

.footer {
  width: 100%;
  float: left;
  border-top: 1px solid #fdfdfd;
  background-color: #edeef1;
  padding-bottom: 0.3rem;
}

.footer .logo {
  width: 100%;
  text-align: center;
  padding: 0.3rem 0 0.1rem;
}

.footer .logo img {
  width: 1.86rem;
  height: 0.44rem;
}

.footer .text {
  width: 100%;
}

.footer .text p {
  line-height: 0.4rem;
  font-size: 0.24rem;
  color: #6f6f6f;
  text-align: center;
}
</style>
